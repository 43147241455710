.my-systems {
    height: 100%;
    min-height: 100vh;

    .main-content {
        margin-top: 0;

        .left-side {
            width: 37%;
            padding: 12px;

            .alarm-item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: 2vh;

                img {
                    height: 72px;
                }

                label {
                    font-size: 24px;
                }
            }

            table.default {
                // border-collapse: separate;
                font-size: 16px;

                thead {
                    th {
                        background-color: #49a3f1;
                        color: white;
                        padding: 3px 6px;
                        cursor: pointer;
                    }
                }

                tbody {
                    td {
                        background-color: lightgray;
                        color: black;
                        padding: 2px;
                        font-size: 14px;
                    }
                }
            }
        }

        .left-side.reversed {

            table.default {
                // border-collapse: separate;
                font-size: 24px;
                margin-left: 3vw;
                margin-top: 3vh;
                border-bottom: 1px solid;

                thead {
                    th {
                        background: transparent;
                        color: #49a3f1;
                        padding: 3px 6px;
                        text-align: left;
                    }
                }

                tbody {
                    td {
                        background-color: transparent;
                        color: white;
                        padding: 2px;
                        height: 30px;
                        font-size: 21px;
                    }
                }
            }


            table.default.red-marker {
                thead {
                    th {
                        color: red;
                    }
                }
            }

            .table-content {
                width: 96%;
                margin-left: 4%;
                margin-top: 3vh;

                table {
                    margin: 0;

                    tr.selected {
                        td{
                            color: #F7931E;
                            font-weight: 600;
                        }
                    }

                    td.success{
                        color: green !important;
                    }
                }
            }

            .button-area {
                padding: 12px 2vw;
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;

                button {
                    height: 36px;
                    color: white;
                    margin-left: 6px;
                    width: 120px;
                    border: none;
                    border-radius: 6px;

                }

                button:hover {
                    box-shadow: 0rem 0.875rem 1.625rem -0.75rem rgba(26, 115, 232, 0.4), 0rem 0.25rem 1.4375rem 0rem rgba(26, 115, 232, 0.15), 0rem 0.5rem 0.625rem -0.3125rem rgba(26, 115, 232, 0.2);
                    cursor: pointer;
                }



                .btn-radar-chart {
                    background-color: rgba(245, 198, 69, 0.96);
                }

                .btn-fft {
                    background-color: rgb(26, 115, 232);
                }

                .btn-waveform {
                    background-color: rgb(76, 175, 80);
                }
            }

        }

        .right-side {
            height: 48vh;
            width: 63%;

            .marker-item {
                height: 42px;

            }

            .marker-item:hover {
                cursor: pointer;
            }


            .mapboxgl-popup-content {
                padding: 0;
                background-color: whitesmoke;

                table {
                    // border-collapse: separate;
                    font-size: 16px;

                    thead {
                        th {
                            background-color: #49a3f1;
                            color: white;
                            padding: 3px 6px;
                            cursor: pointer;
                        }
                    }

                    tbody {
                        td {
                            background-color: lightgray;
                            color: black;
                            padding: 2px;
                            font-size: 14px;
                        }
                    }
                }


                table.red-marker {

                    thead {
                        th {
                            background-color: red;
                        }
                    }

                }

                .mapboxgl-popup-close-button {
                    display: none;
                }
            }

            .button-area {
                padding: 12px 0;
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;

                button {
                    height: 36px;
                    color: white;
                    margin-left: 12px;
                    width: 25%;
                    border: none;
                    border-radius: 6px;

                }

                button:hover {
                    box-shadow: 0rem 0.875rem 1.625rem -0.75rem rgba(26, 115, 232, 0.4), 0rem 0.25rem 1.4375rem 0rem rgba(26, 115, 232, 0.15), 0rem 0.5rem 0.625rem -0.3125rem rgba(26, 115, 232, 0.2);
                    cursor: pointer;
                }

                .expand-map {
                    background-color: yellowgreen;
                }

                .expand-map.close-map {
                    display: none;
                }

                .btn-radar-chart {
                    background-color: #1A73E8;
                    margin-right: 6px;
                }

                .list-all {
                    background-color: rgb(76, 175, 80);
                }
            }
        }
    }

    .main-content.hide-menu {
        .left-side {
            display: none;
        }

        .right-side {
            width: 100%;
            height: 60vh;

            .button-area {
                .expand-map {
                    display: none;
                }

                .expand-map.close-map {
                    display: block;
                }
            }

        }
    }

}