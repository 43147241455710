.radar-chart {
    height: 100%;
    min-height: 100vh;

    .main-content {
        margin-top: 0;
        // height: 66vh;

        .left-side {
            width: 48%;
            padding: 12px;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            table {

                thead {
                    th {
                        font-size: 24px;
                        line-height: 60px;
                        min-width: 180px;
                        text-align: left;
                    }
                }

                tbody {
                    td {
                        font-size: 21px;
                    }
                    tr.selected{
                        td{
                            color: #F7931E;
                        }
                    }
                }
            }

            .marker-list {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 3vh;
                width: 100%;

                .marker-item {
                    background-color: #F7931E;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 49%;
                    height: 120px;
                    cursor: pointer;

                    img {
                        width: 70%;
                        max-height: 90%;
                    }

                    label {
                        color: black;
                        font-size: 42px;
                        font-weight: 600;
                        cursor: pointer;
                    }
                }

            }


            .legend-items {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                width: 100%;
                flex-direction: column;
                padding: 18px;


                .legend-item {
                    width: 180px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 6px;

                    .color-mark {
                        background: rgb(146, 208, 80);
                        width: 36px;
                        height: 36px;
                        border-radius: 6px;
                        border: 1px solid #2f528f;
                    }

                    label {
                        margin-left: 12px;
                        font-size: 18px;
                    }
                }
            }

            .button-area {
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: flex-start;
                margin-top: 3vh;

                button {
                    height: 36px;
                    color: white;
                    width: 49%;
                    border: none;
                    border-radius: 6px;
                }

                button:hover {
                    box-shadow: 0rem 0.875rem 1.625rem -0.75rem rgba(26, 115, 232, 0.4), 0rem 0.25rem 1.4375rem 0rem rgba(26, 115, 232, 0.15), 0rem 0.5rem 0.625rem -0.3125rem rgba(26, 115, 232, 0.2);
                    cursor: pointer;
                }

                .expand-map {
                    background-color: yellowgreen;
                }

                .expand-map.close-map {
                    display: none;
                }

                .btn-radar-chart {
                    background-color: #1A73E8;
                }

                .list-all {
                    background-color: rgb(76, 175, 80);
                    // background-color: #1A73E8;

                }
            }
        }

        .right-side {
            width: 52%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            >table {
                // border-collapse: separate;
                font-size: 16px;
                position: absolute;

                thead {
                    th {
                        background-color: #49a3f1;
                        color: white;
                        padding: 3px 6px;
                    }
                }

                tbody {
                    td {
                        background-color: lightgray;
                        color: black;
                        padding: 2px;
                        font-size: 14px;
                    }
                }
            }

            >table.red-marker {
                thead {
                    th {
                        background-color: red;
                    }
                }
            }

            .btn-systems{
                display: flex;
                justify-content: center;
                align-items: center;
                button{
                    margin: 0 6px;
                    background-color: #F7931E;
                    width: 90px;
                    border: none;
                    font-size: 18px;
                    border-radius: 3px;
                    cursor: pointer;
                    height: 26px;
                    line-height: 26px;
                }

                button.selected{
                    color: white;

                }
            }






        }
    }

}