.header {
    width: 100%;

    .main-comp {
        .management-board {
            .locked {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                background: rgba(25, 25, 25, 0.65);
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;

                img {
                    height: 24px;
                }
            }
        }

    }

    .head-bar{
        .right-side{
            cursor: pointer;
        }
    }
}