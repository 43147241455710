.teammate-card {
    width: 100%;
    height: 120px;
    border: 2px solid #F9F9F9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .user-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 50%;
        align-items: center;
        img {
            height: 84px;
            width: 84px;
            border-radius: 50%;
            margin-left: 12%;
        }
        align-items: center;
        >div {
            margin-left: 6%;
            h4 {
                font-size: 18px;
                margin: 0;
            }
            >p {
                font-size: 16px;
                font-family: RoobotoLight;
                margin: 0;

            }
        }
    }

    .control-buttons{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        width: 50%;
        img{
            height: 48px;
        }

        >div{
            margin-right: 24%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            cursor: pointer;
            label{
                margin-top: 12px;
            }
        }
    }


}

.teammate-card.approved-card {

    justify-content: center;
    align-items: center;
    border-color: #00FF08;
    color: #00FF08;
    text-align: center;
    flex-direction: column;

}


.teammate-card.declined-card {

    justify-content: center;
    align-items: center;
    border-color: #FC0000;
    color: #FC0000;
    text-align: center;
    flex-direction: column;

}
