.layout-container {
    font-family: RoobotoRegular;
    display: block;
    min-height: 100vh;

    >div:first-child {
        padding-bottom: 64px;
    }

    div.ui.grid {
        margin: 0;
        padding: 0;
        // display: block;
        min-height: 100vh;
    }

    .head-bar {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2.4vh 6%;
        padding-top: 3vh;

        .left-side {
            h2 {
                font-size: 36px;
                font-family: RoobotoRegular;
                cursor: pointer;
            }
        }

        .right-side {
            display: flex;
            justify-content: center;
            align-items: flex-end;

            a {
                color: #F9F9F9;
                cursor: pointer;
                width: 120px;
                font-size: 20px;
            }

            img {
                height: 54px;
            }
        }
    }

    .main-comp {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: rgba($color: white, $alpha: 0.15);
        padding: 2vh 6%;

        >div {
            width: 50%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .user-info {
            img {
                height: 196px;
                width: 196px;
                border-radius: 50%;
            }

            align-items: center;

            >div {
                margin-left: 6%;

                h4 {
                    font-size: 21px;
                }

                >p {
                    font-size: 18px;
                    font-family: RoobotoLight;

                    img {
                        height: 24px;
                        width: 42px;
                    }
                }
            }
        }

        .management-board {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;

            div.item {
                margin: 6px 0;
                width: 49%;
                height: 64px;
                border: 2px solid #F9F9F9;
                padding: 3%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                cursor: pointer;


                >img {
                    height: 42px;
                    width: 42px;
                }

                >svg {
                    height: 42px;
                    width: 42px;
                }

                label {
                    font-size: 18px;
                    margin-left: 6%;
                    font-family: RoobotoLight;
                    cursor: pointer;
                }

                .alert-badge {
                    right: 0;
                    top: -21px;
                    font-size: 18px;
                    width: 42px;
                    height: 42px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    background: #FC0000;
                    font-family: RoobotoLight;
                }

            }

            div.item:hover {
                background: #F7931E;
                color: black;
                border: none;

                >svg {
                    path {
                        fill: black !important;
                    }

                    ellipse {
                        fill: black !important;

                    }
                }
            }
        }
    }

    .header-title {
        text-align: center;
        margin-bottom: 0;
    }

    .header-content {
        width: 100%;
        padding: 3vh 6%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .left {
            min-width: 16%;

            h3 {
                vertical-align: middle;
            }

            .red-label {
                color: #FC0000;
                font-size: 42px;
            }

            .region-select {
                cursor: pointer;
            }
        }

        .right {
            display: flex;
            justify-content: flex-end;
            min-width: 16%;

            label {
                cursor: pointer;
            }
        }
    }

    .main-content {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 3vh;
        width: 100%;
        padding: 2vh 1%;
        flex-wrap: wrap;

        .teammate-card {
            margin-top: 6px;
        }

        .teammate-card:first-child {
            margin: 0;
        }

        .mysystem-card {
            margin-right: 1%;
            margin-top: 6px;
            cursor: pointer;
        }

        .region-item {

            border: 2px solid #F9F9F9;
            cursor: pointer;
            width: 19%;
            min-width: 240px;
            margin-right: 1%;
            height: 240px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            position: relative;
            margin-bottom: 1vh;


            >div.locked {
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba($color: #191919, $alpha: 0.65);
                z-index: 1;

                img {
                    height: 30px;
                    margin-left: 12px;
                    margin-top: 12px;
                }
            }

            .assign-sites:hover {
                cursor: pointer;
                color: #F7931E;
            }

        }

        .select-item {
            border: 2px solid #F9F9F9;
            cursor: pointer;
            width: 32%;
            margin-right: 1%;
            height: 132px;
            min-width: 320px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            margin-top: 12px;
            padding: 0 6%;
            text-align: center;
            line-height: 30px;
            position: relative;

            .status-led {
                position: absolute;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 13px;
                right: 0;
                bottom: 0;

                .led {
                    margin-left: 12px;
                }

            }
        }

        table {
            tr {
                td {
                    font-size: 16px;
                    cursor: pointer;
                }

                td.success {
                    color: green;
                }
            }
        }

        #indoor-map {
            height: 100%;
            background-color: white;
            position: relative;

            .main {
                height: 100%;
            }

            .floor-table {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                table {
                    position: absolute;
                    thead {
                        th {
                            background-color: #49a3f1;
                            color: white;
                            padding: 3px 6px;
                            cursor: pointer;
                        }
                    }
                    tbody {
                        td {
                            background-color: lightgray;
                            color: black;
                            padding: 2px;
                            font-size: 14px;
                        }
                    }
                }
            }

            table.red-marker {
                thead {
                    th {
                        background-color: red;
                    }
                }
            }
        }
    }

    .main-content.reassign {
        flex-direction: column;
        align-items: center;
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 6vh;

        .description {
            font-size: 21px;


        }

        img.avartar {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            margin: 12px;

        }

        >p {
            max-width: 480px;
            text-align: center;
        }

        .region-item {
            width: 100%;
            margin-right: 0;
            height: 96px;
            margin-top: 12px;

            position: relative;

            .select-option {
                position: absolute;
                right: 2%;
                border: 2px solid #F9F9F9;
                width: 42px;
                height: 42px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .led {
        background: #00FF08;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        margin-right: 12px;
    }

    .led-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 13px;
        margin-top: 6px;

    }

    .inactive.led {
        background: #FC0000;
    }

    p.user-email {
        width: 100%;
        text-align: left;
        margin-bottom: 0;
        font-size: 18px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.42);
    }

    .footer-div {
        ul {
            margin-bottom: 18px;

            li {
                font-size: 14px;
            }
        }

        p {
            font-size: 12px;
        }
    }
}


@media only screen and (max-width: 1200px) {
    .layout-container {

        .header-content .left h2.region-select {
            font-size: 18px;
        }

        .main-comp {

            .management-board div.item {

                >svg {
                    width: 36px;
                    height: 36px;
                }

                label {
                    font-size: 14px;
                }

            }

            .user-info {
                >div>p {
                    margin-bottom: 6px;
                    font-size: 14px;
                }

                img {
                    height: 144px;
                    width: 144px;
                }
            }
        }


        .head-bar {
            padding: 2vh 6%;
            padding-top: 5vh;

            .left-side h1 {
                font-size: 32px;
            }

            .right-side {
                a {
                    font-size: 21px;
                }

                img {
                    height: 48px;
                }

            }

        }
    }
}

@media only screen and (max-width: 992px) {
    .layout-container {

        .main-comp {
            flex-direction: column;

            >div {
                width: 100%;
            }

            .management-board {
                margin-top: 1vh;
            }

            .user-info {
                >div>p {
                    margin-bottom: 1vh;
                    font-size: 18px;
                }
            }
        }

        .head-bar .right-side a {
            width: 96px;
            font-size: 18px;
        }



        .main-content {
            margin-top: 0;
            justify-content: center;

            .myteam-card {
                min-width: 272px;
                height: 260px;
            }

            .teammate-card .control-buttons>div {
                margin-right: 6%;
            }
        }

        .main-content.reassign .region-item {
            height: 72px;
        }

        .footer-div {
            ul {
                font-size: 16px;
                right: 6vw;

                li:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }

        .header-content .left {
            font-size: 18px;

            .red-label {
                font-size: 24px;
            }
        }

        .main-content .region-item {
            font-size: 18px;
            height: 160px;
        }

        .main-content .select-item {
            font-size: 18px;
            height: 120px;
        }

    }



}

@media only screen and (max-width: 768px) {
    .layout-container {

        .head-bar .left-side h1 {
            font-size: 24px;
        }


        .main-comp {


            .user-info {
                >div>p {
                    font-size: 14px;
                }

                img {
                    height: 96px;
                    width: 96px;
                }
            }
        }

        .header-title {
            font-size: 18px;
        }


    }
}