.footer-div{
    position: relative;
    width: 100%;
    color: #F9F9F9;
    padding: 0px 6%;

    ul{
        position: absolute;
        display: flex;
        bottom: 1vh;
        list-style: none;
        padding: 0;
        right: 6vw;
        margin-bottom: 0;
        li{
            margin-right: 12px;
            font-size: 12px;
            cursor: pointer;
        }

        li:hover{
            text-decoration: underline;
        }
    }
    p{
        position: absolute;
        
        right: 6%;
        bottom: 1vh;
    }
}