@font-face {
    font-family: ZektonFont;
    src: url("./fonts/zekton rg.ttf");
}

@font-face {
    font-family: RoobotoLight;
    src: url("./fonts/Roboto-Light.ttf");
}


@font-face {
    font-family: RoobotoRegular;
    src: url("./fonts/Roboto-Regular.ttf");
}


div#root {
    background-color: #191919;
    font-family: RoobotoLight;
    font-weight: 300;
    font-style: normal;
    color: #F9F9F9;
    min-height: 100%;

    .hover-btn:hover {
        button {
            color: #191919 !important;
            border-color: #F7931E !important;
        }
        background: #F7931E;
    }

    .mt-3{
        margin-top: 3vh;
    }
    .mt-6{
        margin-top: 6vh;
    }
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-text-fill-color: #F9F9F9;
    box-shadow: 0 0 0px 1000px #191919 inset;
    transition: background-color 5000s ease-in-out 0s;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(247, 147, 30, 0.8);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(247, 147, 30);
}