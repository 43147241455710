div#root {
    .flex-center {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transition: all 0.5s;

    }

    .flex-center.hide {
        display: none;
    }

    form {
        height: 100%;

        div.form-container {
            padding: 24px 12px;
            height: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-direction: column;

            .up-center-dev {
                width: 100%;
                flex-grow: 0.4;
                justify-content: flex-end;

                img {
                    height: 12vh;
                }
            }

            .down-center-div {
                width: 100%;
                flex-grow: 0.6;
                justify-content: space-around;
                margin-top: 3vh;
            }

            .down-center-div.terms-condition{
                text-align: left;
                p, h5{
                    text-align: left;
                    width: 100%;
                }
                h1{
                    font-size: 18px;
                    margin-top: 6px;
                    width: 100%;
                }

                h2{
                    font-size: 16px;
                    margin-top: 6px;
                    width: 100%;
                }

                h3{
                    font-size: 15px;
                    margin-top: 6px;
                    width: 100%;
                }

                ul{
                    width: 100%;
                }
            }
        }
    }


    .custom-field {
        width: 100%;
        margin-top: 18px;

        .MuiInputBase-root.MuiInput-root:before {
            border-bottom: 1px solid rgba(255, 255, 255, 0.42);
        }

        .MuiFormLabel-root.MuiInputLabel-root {
            color: rgba(255, 255, 255, 0.6);
            font-size: 15px;
        }

        .MuiInputBase-input.MuiInput-input {
            color: #f9f9f9 !important;
            font-size: 18px;
            background: none;
            background-image: none !important;
            background-color: transparent !important;
        }

        .MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
            color: #F7931E;
        }

        .MuiNativeSelect-select.MuiInputBase-input.MuiInput-input {
            font-size: 15px;
        }
    }

    .client-access {
        font-size: 36px;
        font-family: ZektonFont;
        font-weight: 200;
    }

    .sign-up-account {
        font-size: 24px;
        font-family: RoobotoRegular;
        font-weight: 200;
        margin-top: 3vh;
    }

    .client-access-below {
        font-size: 24px;
    }

    .sign-in-btn {
        width: 100%;
        margin-top: 32px;

        button {
            color: #F9F9F9;
            border-color: #F9F9F9;
            width: 100%;
            font-size: 16px;
            text-transform: unset;
            font-weight: 600;
        }
    }

    .MuiSvgIcon-root {
        color: #F7931E;
    }

    .remember-me {
        margin-top: 21px;
    }

    .forgot-password {
        margin-top: 48px;
        cursor: pointer;
    }

    .sign-up {
        margin-top: 18px;
        cursor: pointer;

        a {
            cursor: pointer;
            color: #F7931E;
        }
    }

    .logout-success {
        margin-top: 6vh;
        margin-bottom: 12vh;
        font-size: 21px;
    }

    .next-btn {
        font-size: 18px;
        cursor: pointer;
    }

    .back-btn {
        position: fixed;
        top: 12vh;
        right: 6vw;
    }

    span.MuiFormLabel-asterisk {
        color: #FC0000;
    }

    .upload-image {
        width: 272px;
        height: 272px;
        border-radius: 50%;
        background: #F9F9F9;
        border-radius: 50%;
        margin-top: 12px;
        margin-bottom: 24px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        cursor: pointer;
        overflow: hidden;

        >img.user-icon {
            width: 100%;
            margin: auto 0;
        }

        img {
            width: 60%;
            margin-left: 90%;
        }

        button {
            position: absolute;
            width: 64px;
            height: 64px;
            background: none;
            border: none;
            padding: 0;
        }

        >input {
            display: none;
        }
    }
}


@media only screen and (max-width: 768px) {
    div#root {
        .client-access {
            font-size: 24px;
        }

        .client-access-below {
            font-size: 18px;
        }

        form div.form-container .up-center-dev img{
            height: 9vh;
        }
    }

}