.mysystem-card {
    min-width: 320px;
    width: 24%;
    height: 160px; 
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F7931E;
    position: relative;
    img{
        max-height: 100px;
        max-width: 72%;
    }

    h1{
        color: #191919;
        font-size: 54px;
    }

    div.locked{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($color: #191919, $alpha: 0.65);
        z-index: 1;

        img{
            height: 30px;
            margin-left: 12px;
            margin-top: 12px;
        }
    }


}