.dialog-content{
    background: #191919;
    color: white;
    max-width: 600px;
    width: 92vw;
    padding: 6vh 6%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    flex-direction: column;
    position: relative;

    .subtitle{
        color: #F7931E;
        font-size: 21px;
    }

    img.avartar{
        width: 256px;
        height: 256px;

        margin-top: 18px;
        border-radius: 50%;
    }

    .description{
        font-size: 18px;
        p{
            margin-bottom: 6px;
        }
    }

    .select-part{
        width: 100%;

        .select-item{
            width: 100%;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            border: 2px solid #FFFFFF;
            margin-top: 1vh;
            cursor: pointer;
        }

        .select-item.selected{
            background: #F7931E;
            color: #191919;
            border: none;
        }
    }

    .btn-next{
        font-size: 18px;
        margin-top: 3vh;
        cursor: pointer;
    }

    .btn-exit{
        position: absolute;
        right: 6%;
        top: 3vh;
        width: 18px;
        cursor: pointer;
    }

    .result{
        color: #F7931E;
        line-height: 32px;
    }

    .assignment-success{
        margin: 9vh 0;
        img.avartar{
            height: 172px;
            width: 172px;
        }
    }

    .access-denied{
        margin-top: 3vh;
    }

}


.filter-dlg{
    .select-part{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .select-item{

            width: 160px;
            margin: 5px;

        }
    }
}

.MuiDialog-container{

    .MuiDialog-paper{
        overflow-x: hidden;
    }
}