div#root {
    .login-page {
        width: 100%;
        height: 100vh;

        .header-section {
            height: 13vh;

            img {
                height: 9.6vh;
                margin-top: 2vh;
                margin-left: 3vw;
            }
        }

        .main-section {
            height: 80vh;
            background-image: url("../assets/imgs/login-bg.webp");
            background-size: cover;
            background-position: center;

            .main-content {
                max-width: 1200px;
                padding: 0 2vw;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                padding-top: 6vh;
                .left-side{
                    h1{
                        font-size: 48px;
                        margin-top: 3vh;
                    }

                    h2{
                        font-weight: 200;
                        font-size: 28px;
                    }
                }

                .right-side{
                    min-width: 25%;
                    .remember-me{
                        margin-top: 0;
                    }

                    .sign-in-btn{
                        margin-top: 24px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        button{
                            color: #008AFC;
                            border-color: green;
                            width: 100%;
                            font-size: 16px;
                            text-transform: unset;
                            font-weight: 100;
                            width: 49%;
                        }

                        button:first-child{
                            color: #E21C21;
                        }

                      
                    }
                }
            }
        }

    }
}