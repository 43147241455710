.myteam-card {
    min-width: 320px;
    width: 24%;
    height: 284px; 
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 2px solid #F9F9F9;
    margin-right: 1%;
    flex-direction: column;
    margin-bottom: 1vh;
    img.avartar-img{
        max-height: 100px;
        max-width: 72%;
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }

    p{
        margin: 0;
        font-family: RoobotoLight;
        font-size: 12px;
        padding: 0 12px;
        text-align: center;
    }

    a{
        margin-top: 12px;
        color: #F9F9F9;
        text-decoration: underline;
        cursor: pointer;
    }
    a:hover{
        color: #F7931E;
    }

    img.mark-icon{
        position: absolute;
        top: 12px;
        right: 12px;
        width: 24px;

    }


    img.message-icon{
        position: absolute;
        top: 54px;
        right: 12px;
        width: 24px;
    }

  

}